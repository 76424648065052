.input-amount {
  padding: .1em;
  background-color: #efefef;
  border-radius: .3em;
}

.input-amount input {
  padding: 10px;
  border: 0;
  border-radius: 5px;
  background-color: #efefef;
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}