.select-with-modal {    display: flex;
  flex-direction: horizontal;
  align-items: center;
  cursor: pointer;
  background: #fff;
  border: 0;
  border-radius: .3em;
  width: 12em;
  height: 3em;
  color: black;
}


.select-with-modal:hover {
  background-color: rgb(237, 237, 237);
  animation: modal-hover .2s;
} 

.selected {
  color: gray;
}

.selected:hover {
  background: white;
  cursor: default;
  animation: none !important;
}

@keyframes modal-hover {
  from {
    background-color: white;
  }
  to {
    /* background-color: rgb(88 88 88); */
  background-color: rgb(237, 237, 237);
    /* background-color: rgb(74 74 74); */
    /* background-color: #222; */
  }
}

.waiting-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgb(147, 147, 147);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.select-with-modal div {
  display: flex;
  justify-content: center; /* Centers text horizontally in the text div */
  align-items: center; /* Centers text vertically in the text div */
  padding: .3em;
}

.select-with-modal img {
  width: 1.7em;
  height: 1.7em;
  border-radius: .3em;
}

.disabled * {
    /* background-color: white; */
    color: #c2afa8;
    border: 0;
    border-radius: .3em;
    cursor: none;
    pointer-events: none;
    backdrop-filter: blur(1.5em);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgb(255 255 255 / 0%);
}

.disabled {
  border: 0;
  border-radius: .3em;
  background-color: #00000054;
}

.cancel-selection {
  margin-left: auto;
  padding: 0 !important;
  border-top-right-radius: .3em;
  border-bottom-right-radius: .3em;
  background: #e5e5e5;
  height: 100%;
  width: 3em;
  color: #515151;
}

.cancel-selection:hover {
  cursor: pointer;
  background: #dcdcdc;
  animation: cancel-hover .2s;
}

@keyframes cancel-hover {
  from {
    background-color: #e5e5e5;
  }
  to {
    /* background-color: rgb(88 88 88); */
  background-color: #dcdcdc;
    /* background-color: rgb(74 74 74); */
    /* background-color: #222; */
  }
}