.selection-flow {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: .5em;
}


@media (max-width: 750px) {
  .selection-flow {
    flex-direction: column;
  }
}

.full-screen-arrow {
  visibility: visible;
  padding: .5em;
}

.small-screen-arrow {
  visibility: hidden;
  height: 0;
  width: 0;
}

@media (max-width: 750px) {
  .full-screen-arrow {
    visibility: hidden;
    height: 0;
    width: 0;
    padding: 0;
  }

  .small-screen-arrow {
    visibility: visible;
    height: auto;
    width: auto;
    padding: .5em;
  }
}

