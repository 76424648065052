.modal {
  position: fixed; /* Change from absolute to fixed */
  top: 0; /* Ensure modal opens at the top of the viewport */
  left: 0; /* Ensure modal starts from the left */
  background: black;
  height: 100vh;
  width: 100%;
  color: white;
  z-index: 10;
  backdrop-filter: blur(.5em);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgb(28 28 28 / 80%);
}

.modal-visible {
  animation: appear .2s;
  opacity: 1;
}

.modal-hidden {
  animation: disappear .2s;
  display: none;
  opacity: 0;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal > .exit {
  position: fixed;
  right: 0;
  padding: .4em;
  cursor: pointer;
}

.modal > .modal-title {
      position: fixed;
  left: 50%;
  /* top: 2em; */
  transform: translate(-50%, 0%);
  font-weight: bold;
  font-size: x-large;
  /* background-color: tan; */
  padding: .5em;
  font-stretch: extra-expanded;
}


.modal > .options {
  position: fixed;
  top: 5em;
  /* left: 6em; */
  /* padding: 10em; */
  width: 100%;
}

.modal > .options > .option {
  padding: .5em;
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  cursor: pointer;
  background-color: none;
  padding-left: 10em;
  padding-right: 10em;
  animation: option-non-hover .2s;
}

.modal > .options > .option:hover {
  background-color: #131313;
  animation: option-hover .2s
}

@keyframes option-hover {
  from {
    background-color: none;
  }
  to {
    background-color: #131313;
  }
}

@keyframes option-non-hover {
  from {
    background-color: #131313;
  }
  to {
    background-color: none;
  }
}

.modal > .options img {
  width: 1.7em;
  height: 1.7em;
  border-radius: .3em;
}

.modal > .options > .option > div {
  display: flex;
  padding: .3em;
}


@keyframes fadeIn {
  to {
    opacity: 1;
  }
  from {
    opacity: 0;
  }
}


@media (max-width: 750px) {
  .modal > .modal-title {
    left: 0;
    transform: none;
  }

  .modal > .options > .option {
    padding-left: 1em;
    padding-right: 1em;
  }
}