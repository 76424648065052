/* Bridge is a square box that will have controls in it. */
body {
  background-color: #fff8bc;
  background-image: linear-gradient(43deg, #fff8bc 0%, #C850C0 47%, #6572c7 100%);
  background-attachment: fixed;
}
.bridge {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;


  /* background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%); */
  /* background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%); */

}

.order-wrapper {
}

@media screen and (max-width: 750px) {
  .order-wrapper {
    width: calc(100% - 2em); /* Subtracting 1em margin from each side */
    margin: 0 1em; /* Adding 1em margin on each side */
  }
}

.order-input {
  z-index: 5;
  background-color: rgb(28 28 28 / 40%);
  padding-top: .5em;
}

@media (max-width: 750px) {
  .order-input{
    width: 100%;
  }
}


.account-wrapper {
  height: 0;
}

.account-info {    
  display: flex;
  animation-name: account-slide;
  animation-duration: .2s;
  padding: .5em;
  padding-left: .9em;
  color: white;
}

.account-button {    /* backdrop-filter: blur(1.5em); */
  -webkit-backdrop-filter: blur(10px);
  /* background-color: rgb(68 61 61 / 52%); */
  background-color: black;
  margin-right: .2em;
  padding: .3em;
  border-radius: .2em;
  padding-left: .4em;
  padding-right: .4em;
}

.account-options {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 750px) {
  .account-options {
    display: flex;
    flex-direction: column;
  }
  .account-options > a {
    margin-bottom: .5em;
    padding: 1em;
  }
  .account-button {
    margin-right: 0;
  }
}

@keyframes account-slide {
  0% {
    /* padding: 0; */
    height: 0;
  }
  100% {
    /* padding: .5; */
    height: .9em;
  }
}

.quote-info {
  display: flex;
  justify-content: center;
  color: white;
}

.progress {
  display: flex;
  align-items: center;
  padding: 1em;
  width: 100%;
}

.progress-checkpoint {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-ball {
  height: 1em;
  width: 1em;
  background: rgb(0 0 0/ 12%);
  border-radius: 100%;
}

.progress-checkpoint-connector {
  width: 100%;
  background: rgb(0 0 0/ 12%);
  height: 2px;
}

.done {
  background-color: white;
}

.button {
  font-size: x-large;
  border: none;
  color: white;
  height: 1.3em;
  padding: .5em;
  width: 3em;
  border-radius: .3em;
  margin: .5em;
  background-color: rgb(0 0 0/ 12%);
  animation: button-unhover 1s;
  font-weight: bolder;
  font-stretch: expanded;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  text-decoration: none;

  background: #222;
  animation: button-hover 1s;
}

.sending {
  cursor: default;
  display: flex;
  animation: none;
  justify-content: center;
  align-items: center;
}

.sending:hover {
  background-color: rgb(0 0 0/ 12%);
  animation: none;
  cursor: default;
}

.sent {
  cursor: default;
  display: flex;
  animation: none;
  justify-content: center;
  align-items: center;
}

.sent:hover {
  background-color: rgb(0 0 0/ 12%);
  animation: none;
  cursor: default;
}

@keyframes button-hover {
  0% {
    background-color: rgb(0 0 0/ 12%);
  }
  100% {
    background-color: #222;
  }
}

@keyframes button-unhover {
  0% {
    background-color: #222;
  }
  100% {
    background-color: rgb(0 0 0/ 12%);
  }
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
  padding: 0;
}

.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.loading {
  height: 32px;
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.send-loading {
  height: 32px;
  visibility: visible;
  opacity: 1;
  width: 5em;
}

.no-error {
  visibility: hidden;
  height: 0;
}

.error {
  color: rgb(255 154 154);
  visibility: visible;
}

.box-top {
  background: black;
  width: 100%;
  height: 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;

  backdrop-filter: blur(1.5em);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgb(28 28 28 / 50%);
}

.box-bottom{

  background: black;
  width: 100%;
  height: 1em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;

  backdrop-filter: blur(1.5em);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgb(28 28 28 / 50%);
}

.title {
  color: white;
  padding-left: 1em;
  padding-bottom: 1em;
  font-weight: bold;
  text-transform: uppercase;
  font-size: smaller;
}


label {
  font-weight: bold;
  text-transform: uppercase;
  width: 9em;
  display: inline-block;
}

.alert-wrapper {
  padding: .9em;
}

.transactions-label {
  font-weight: bold;
  text-transform: uppercase;
  width: 13em;
  display: inline-block;
}

input[type="number"] {
  background: none;
  border: none;
  color: #f5dbbd;
  font-size: large;
  padding: 0;
}

::placeholder {
  color: #ebbcbe;
}

input[type="number"]:focus {
  outline: none;
}


.header {    display: flex;
  color: white;
  align-content: flex-end;
  align-items: center;
  width: 90%;
  padding: 1em;
  margin: 1em;
  justify-content: space-between;
}

.header > .logo {
  padding-right: 1em;
  color: white;
}

.header > .header-title {
  color: white;
  font-size: xx-large;
  font-weight: bold;
  font-stretch: extra-expanded;
}

.sub-header {
  color: white;
  font-size: large;
  font-weight: bold;
  font-stretch: extra-expanded;
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.nav { 
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.nav-item {     border-right: 2px solid white;
  padding-right: .3em;
  margin-bottom: .3em;
}
.side-piece {
  width: 10em;
}

@media screen and (max-width: 750px) {
  .header > .logo {
    padding-right: 0;
  }

  .header > .header-title {
    visibility: hidden;
  }

  .side-piece {
    width: 0;
  }
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.socials {
  display: flex;
  padding: 1em;
}

.socials > a {
  display: flex;
  padding: .3em;
  opacity: .8;
  animation: fadeoutsocials .2s;
}

.socials > a:hover {
  opacity: 1;
  animation: fadeinsocials .2s;
}

@keyframes fadeinsocials {
  0% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeoutsocials {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .8;
  }
}

.extra-info {
  font-size: smaller;
}

.announcement {

  padding: 1em;
  color: white;
  /* font-weight: bold; */
  text-align: center;
  font-weight: 200;
}